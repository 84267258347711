.header-cs-text {
    text-align: left;
    margin-bottom: 0;
    font-size: 14px;
}
.logo-img img {
    max-width: 100%;
}
.logo-name {
    color: #012d76;
    display: inline-block;
    padding-left: 23px;
    vertical-align: middle;
    font-size: 28px;
    font-weight: bold;
}
@media screen and (max-width: 767px) {
    .logo-name {
        font-size: 18px;
   }
    .logo-name p {
        font-size: 10px;
   }
    .logo-img {
        max-width: calc(100% - 82px);
   }
    .logo-img img {
        max-width: 38px;
   }
   .toggle_btn {
    font-size: 14px;
   }
}

@media screen and (max-width: 355px) {
    .logo-name {
        font-size: 15px;
   }
}