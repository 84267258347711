.banner {
    color: #000;
}
.banner .img {
    padding-bottom: 250px;
    background-image: url(./../../../public/assets/banner-img.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
.contact-area {
    margin-top: -170px;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .address-section p {
        font-size: 12px;
   }
}
