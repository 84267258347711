@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    color: #4e4e4e;


}
.custom-btn {
  background-color: #012d76 !important;
  border-color: #012d76 !important;
}
.custom-btn:hover {
  background-color: #012d76 !important;   
 }