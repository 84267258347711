footer {
    background-color: #222327;
    color: #fff;
}
footer h4 {
    color: #f09f08;
    margin-bottom: 20px;
}
footer .footer-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
footer .footer-list li {
    margin-bottom: 12px;
}
footer .footer-list li a {
    text-decoration: none;
    color: #fff;
}
.ft-bottom .text-right {
    text-align: right;
}
.cs-text {
    color: #23b9a4;
    text-align: right;
    margin-bottom: 0;
    font-size: 14px;
}
.logo {
    max-width: 238px;
}
@media screen and (max-width: 767px) {
    .ft-bottom .logo {
        max-width: 100%;
        text-align: center;
   }
    .ft-bottom .logo .cs-text {
        text-align: center;
   }
    .ft-bottom .text-right {
        text-align: center;
   }
    .ft-bottom .text-right p {
        font-size: 10px;
        margin-bottom: 0;
   }
}
