.banner-text {
    position: absolute;
    font-size: 50px;
    font-weight: bold;
    top: 50%;
    left: 60%;
    transform: translate(-50%);
}
.banner {
    position: relative;
}
.service-block {
    margin-bottom: 30px;
    padding: 25px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 31px 0px 18px 5px #f7f7f7;
    min-height: 211px;
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
}
.service-block:hover:before {
    top: 0;
}
.service-block::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: red;
    background-color: #fecbca;
    opacity: 0.5;
    transition: 0.3s;
}
@media screen and (max-width: 767px) {
    .banner-text {
        font-size: 15px;
   }
    .service-block {
        font-size: 13px;
        line-height: 1.1;
        padding: 10px;
        margin-bottom: 10px;
   }
    .service-block img {
        max-width: 100%;
   }
    .service-block p {
        margin-top: 8px;
   }
}
